.App {
  max-width: 1920px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  display: block;
  /* display: grid;
  grid-template: minmax(auto, 120px) 1fr minmax(470px, auto) / 1fr;
  grid-template-areas: "header" "main" "footer"; */
  background-color: #000000;
  color: #ffffff;
}
@media screen and (max-width: 430px) {
  .App {
    max-width: 430px;
  }
}
