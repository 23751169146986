.Cart {
  margin-top: 120px;
  width: 100%;
  max-width: 1920px;
  display: grid;
}
@media screen and (max-width: 768px) {
  .Cart {
    margin-top: 70px;
  }
}
