.Tasks {
  width: 100%;
  height: 100%;
  padding: 220px 50px 100px 50px;
  display: block;
  h6 {
    font-family: 'Archivo Narrow';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 200%;
    text-transform: uppercase;
    color: #ffffff;
    padding: 40px 0;
    text-align: center;
    height: 100%;
    width: 100%;
    background: #222222;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__top {
    width: 100%;
    height: 100%;
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: 1fr 50%;
    gap: 20px;
    &__left {
      grid-column: 1 / 2;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      padding: 20px;
      position: relative;
      background-position: center center;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      h5 {
        font-family: 'Archivo Narrow';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        text-transform: uppercase;
        color: #ffffff;
        padding: 40px 20px;
        text-align: center;
        background: #222222;
        border-radius: 12px;
        padding: 20px;
        text-align: center;
        position: absolute;
        bottom: 20px;
        left: 20px;
        width: calc(100% - 40px);
      }
    }
    &__right {
      grid-column: 2 / 3;
      width: 100%;
      height: 100%;
      background: rgba(104, 104, 104, 0.2);
      backdrop-filter: blur(20px);
      border-radius: 20px;
      padding: 20px;
      display: grid;
      grid-template: repeat(3, auto) / 1fr 50%;
      column-gap: 10px;
      row-gap: 20px;
      &__goal {
        grid-column: 1 / 3;
      }
    }
  }
  &__bottom {
    width: 100%;
    height: 100%;
    background: rgba(104, 104, 104, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 20px;
    padding: 20px;
    .Collapsible {
      background: #222222;
      border-radius: 12px;
      padding: 20px;
      margin-bottom: 20px;
      border: 1px solid transparent;
      h4 {
        font-family: 'Archivo Narrow';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        text-transform: uppercase;
        color: #ffffff;
        padding: 40px 0;
        text-align: center;
        padding: 20px;
        cursor: pointer;
      }
    }
    :last-child {
      margin-bottom: 0px;
    }
    .Collapsible:hover {
      border: 1px solid #99daff;
    }
    .Staking {
      display: grid;
      grid-template: repeat(2, auto) / repeat(6, 1fr);
      width: 100%;
      li {
        font-family: 'Archivo Narrow';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: #ffffff;
        opacity: 0.5;
        text-align: center;
        padding: 10px 0;
      }
    }
    .Quests {
      display: grid;
      grid-template: repeat(2, auto) / repeat(5, 1fr);
      width: 100%;
      li {
        font-family: 'Archivo Narrow';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: #ffffff;
        opacity: 0.5;
        text-align: center;
        padding: 10px 0;
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .Tasks {
    padding: 170px 50px 70px 50px;
    .Quests {
      li {
        font-size: 14px;
      }
    }
    .Staking {
      li {
        font-size: 14px;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .Tasks {
    display: block;
    &__top {
      display: grid;
      grid-template: auto auto / 100%;
      &__left {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        width: 100%;
        height: 600px;
        border-radius: 20px;
      }
      &__right {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        width: 100%;
        height: 100%;
      }
    }
    &__bottom {
      width: 100%;
      height: 100%;
    }
  }
}
@media screen and (max-width: 768px) {
  .Tasks {
    padding: 70px 15px 50px 15px;
    h6 {
      font-size: 16px;
    }
    &__top {
      &__left {
        h5 {
          font-size: 16px;
        }
      }
    }
    &__bottom {
      .Collapsible {
        h4 {
          font-size: 16px;
        }
      }
      .Staking {
        display: grid;
        grid-auto-flow: column;
        grid-template: repeat(6, auto) / repeat(2, 1fr);
        li {
          font-size: 14px;
          text-align: center;
        }
      }
      .Quests {
        display: grid;
        grid-auto-flow: column;
        grid-template: repeat(5, auto) / repeat(2, 1fr);
        li {
          font-size: 14px;
          text-align: center;
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .Tasks {
    &__top {
      &__left {
        height: 400px;
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .Tasks {
    padding: 100px 13px 50px 13px;
    h6 {
      font-size: 14px;
    }
    &__top {
      &__left {
        height: 300px;
      }
    }
  }
}
