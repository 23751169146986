.About {
  width: 100%;
  padding: 220px 50px 50px 50px;
  display: block;
  h2 {
    font-family: 'Archivo Narrow';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 100%;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 20px;
  }
  p {
    font-family: 'Archivo Narrow';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 100%;
    text-transform: uppercase;
    color: #ffffff;
    opacity: 0.5;
    margin-bottom: 20px;
  }
  &__welcome {
    width: 100%;
    margin-bottom: 100px;
    &__text {
      margin-bottom: 50px;
      .welcome {
        font-family: 'Archivo Narrow';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 100%;
        text-transform: uppercase;
        color: #ffffff;
        opacity: 0.5;
        margin-bottom: 20px;
      }
    }
  }
  &__series,
  &__NFT {
    display: flex;
    margin-bottom: 100px;
    &__text {
      width: 48%;
      margin-right: 50px;
      align-self: center;
    }
    &__img {
      width: 50%;
      height: auto;
      position: relative;
      .swiper {
        width: 100%;
        height: 100%;
      }
      .swiper-slide {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
    }
  }
  &__merch,
  &__gamification {
    display: flex;
    margin-bottom: 100px;
    &__text {
      width: 48%;
      margin-left: 50px;
      align-self: center;
    }
    &__img {
      width: 50%;
      height: 900px;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .About {
    padding: 170px 50px 0px 50px;

    &__merch,
    &__gamification {
      &__img {
        width: 50%;
        height: 700px;
        position: relative;
      }
    }
  }
}
@media screen and (max-width: 1133px) {
  .About {
    h2 {
      font-size: 48px;
    }
    p {
      font-size: 40px;
    }
    &__welcome {
      &__text {
        .welcome {
          font-size: 46px;
        }
      }
    }
    &__series,
    &__NFT {
      display: flex;
      flex-direction: column;
      margin-bottom: 100px;
      &__text {
        width: 100%;
        margin-bottom: 50px;
        margin-right: 0px;
      }
      &__img {
        width: 100%;
        height: auto;
      }
    }
    &__merch,
    &__gamification {
      display: flex;
      flex-direction: column-reverse;
      &__text {
        width: 100%;
        margin-left: 0px;
        margin-bottom: 50px;
      }
      &__img {
        width: 100%;
        height: 800px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .About {
    padding: 100px 15px 0px 15px;
    h2 {
      font-size: 24px;
      margin-bottom: 20px;
    }
    p {
      font-size: 16px;
      margin-bottom: 10px;
    }
    &__welcome {
      margin-bottom: 50px;
      &__text {
        margin-bottom: 20px;
        .welcome {
          font-size: 20px;
          margin-bottom: 10px;
        }
      }
    }
    &__series,
    &__NFT {
      margin-bottom: 50px;
      &__text {
        margin-bottom: 20px;
      }
    }
    &__merch,
    &__gamification {
      margin-bottom: 50px;
      &__text {
        margin-bottom: 20px;
      }
      &__img {
        height: 400px;
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .About {
    padding: 100px 13px 00px 13px;
    h2 {
      font-size: 30px;
    }
    &__merch,
    &__gamification {
      &__img {
        height: 300px;
      }
    }
  }
}
