.Main {
  margin-top: 120px;
  &__description {
    font-family: 'Archivo Narrow';
    font-style: normal;
    font-weight: 400;
    font-size: 46px;
    line-height: 100%;
    text-transform: uppercase;
    color: #ffffff;
    padding: 100px 50px;
    width: 100%;
    :nth-child(2) {
      margin: 30px 0;
    }
  }
}
@media screen and (max-width: 1440px) {
  .Main {
    &__description {
      font-size: 40px;
    }
  }
}
@media screen and (max-width: 768px) {
  .Main {
    margin-top: 70px;
    &__description {
      padding: 0px 15px;
      font-size: 20px;
      :nth-child(2) {
        margin: 20px 0;
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .Main {
    &__description {
      padding: 0px 13px;
    }
  }
}
