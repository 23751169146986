.MerchGallery {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 13px;
  padding: 50px;
}
@media screen and (max-width: 768px) {
  .MerchGallery {
    padding: 50px 15px;
  }
}
@media screen and (max-width: 320px) {
  .MerchGallery {
    padding: 50px 13px;
  }
}
