.Carousel {
  padding: 60px 50px;
  width: 100%;
  overflow: hidden;
  position: relative;
  .Arrow__left {
    left: 50px;
  }
  .Arrow__right {
    right: 50px;
  }
}

@media screen and (max-width: 768px) {
  .Carousel {
    padding: 100px 15px;
    .Arrow__left {
      left: 15px;
    }
    .Arrow__right {
      right: 15px;
    }
  }
}
@media screen and (max-width: 320px) {
  .Carousel {
    padding: 50px 13px;
    .Arrow__left {
      left: 13px;
    }
    .Arrow__right {
      right: 13px;
    }
  }
}
.Carousel__Heading {
  height: 40px;
  position: relative;
  * {
    font-family: 'Archivo Narrow';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 93%;
    text-transform: uppercase;
    color: #ffffff;
  }
  a {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    cursor: pointer;
  }
  h3 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
  }
}

.Carousel_episodeSeries {
  .Carousel__Heading {
    a {
      display: none;
    }
  }
}
.Carousel_episode {
  .Card {
    height: 590px;
  }
}
.Carousel_merch {
  .Card {
    height: 400px;
  }
}
@media screen and (max-width: 1440px) {
  .Carousel_episode {
    .Card {
      height: 560px;
    }
  }
  .Carousel_merch {
    .Card {
      height: 370px;
    }
  }
}
@media screen and (max-width: 1133px) {
  .Carousel_episode {
    .Card {
      height: 530px;
    }
  }
  .Carousel_merch {
    .Card {
      height: 350px;
    }
  }
}
@media screen and (max-width: 990px) {
  .Carousel_episode {
    .Card {
      height: 560px;
    }
  }
  .Carousel_merch {
    .Card {
      height: 400px;
    }
  }
}
@media screen and (max-width: 768px) {
  .Carousel_episode {
    .Card {
      height: 530px;
    }
  }
  .Carousel_merch {
    .Card {
      height: 350px;
    }
  }
}
@media screen and (max-width: 480px) {
  .Carousel_episode {
    .Card {
      height: 560px;
    }
  }
  .Carousel_merch {
    .Card {
      height: 400px;
    }
  }
}
@media screen and (max-width: 375px) {
  .Carousel_episode {
    .Card {
      height: 530px;
    }
  }
  .Carousel_merch {
    .Card {
      height: 350px;
    }
  }
}
@media screen and (max-width: 320px) {
  .Carousel_episode {
    .Card {
      height: 530px;
    }
  }
  .Carousel_merch {
    .Card {
      height: 300px;
    }
  }
}
