.Enter {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  img {
    position: absolute;
    top: 50px;
    left: 50px;
    z-index: 10;
    width: 300px;
  }
  video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }
  button {
    position: absolute;
    bottom: 50px;
    right: 50px;
    z-index: 10;
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 93%;
    text-transform: uppercase;
    background: #e6321f;
    border-radius: 10px;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0);
    cursor: pointer;
    width: 200px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 2px 2px 0px rgba(0, 0, 0, 1);
  }
}
@media screen and (max-width: 768px) {
  .Enter {
    img {
      top: 50px;
      left: calc(50% - 150px);
    }
    button {
      bottom: 20%;
      right: calc(50% - 100px);
    }
  }
}
