.CartList {
  padding: 200px 0;
  display: grid;
  justify-self: center;
  width: 60%;
  height: fit-content;
  grid-template: 1fr / 1fr 40%;
  column-gap: 30px;
  &__list {
    grid-column: 1 / 2;
    background: rgba(104, 104, 104, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 20px;
    padding: 20px;
  }
  &__buy {
    height: fit-content;
    width: 100%;
    grid-column: 2 / 3;
    background: rgba(104, 104, 104, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 20px;
    padding: 20px;
    &__heading {
      display: flex;
      justify-content: space-between;
      h1 {
        font-family: 'Archivo Narrow';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        color: #ffffff;
        text-transform: uppercase;
        :last-child {
          padding-left: 25px;
        }
      }
    }
    a {
      font-family: 'Archivo Narrow';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      margin: 25px 0;
      background: #222222;
      border-radius: 12px;
      width: 100%;
      height: 60px;
    }
    &__charity,
    &__delivery {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      margin-bottom: 15px;
      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
      p {
        font-family: 'Archivo Narrow';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #6b6b6b;
        text-transform: uppercase;
      }
    }
    &__delivery {
      margin-bottom: 0px;
    }
  }
}
@media screen and (max-width: 1440px) {
  .CartList {
    padding: 150px 0;
    width: 70%;
    height: fit-content;
    &__buy {
      &__heading {
        font-size: 20px;
      }
    }
  }
}
@media screen and (max-width: 1133px) {
  .CartList {
    width: 55%;
    padding: 100px 0;
    grid-template: auto auto / 100%;
    column-gap: 0px;
    row-gap: 40px;
    &__list {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      padding: 25px;
    }
    &__buy {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      padding: 25px;
      a {
        width: 220px;
        margin: 25px auto;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .CartList {
    padding: 50px 15px;
    width: 80%;
  }
}
@media screen and (max-width: 480px) {
  .CartList {
    width: 100%;
    row-gap: 20px;
    &__list {
      padding: 20px;
    }
    &__buy {
      padding: 20px;
    }
  }
}
@media screen and (max-width: 320px) {
  .CartList {
    padding: 40px 13px;
    &__list {
      padding: 15px;
    }
    &__buy {
      padding: 15px;
    }
  }
}
