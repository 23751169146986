.Header {
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  width: 100%;
  height: 120px;
  padding: 0px 50px;
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // grid-template: 1fr / auto 1fr auto;
  transition: all 0.5s ease-in-out;
  &_black {
    background: #000;
  }
  &__logo {
    cursor: pointer;
    img {
      height: 40px;
      width: auto;
    }
  }
  &__menu {
    // grid-column: 3 / 4;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    &__list {
      display: flex;
      align-items: center;
      justify-content: end;
      padding-left: 50px;
      li {
        border: 1px solid transparent;
        margin-right: 40px;
        padding: 10px 12px;
        cursor: pointer;
      }
      li:last-child {
        margin-right: 0px;
        padding: 0px 0px;
        border: none;
      }
      li:hover {
        border: 1px solid #ffffff;
        border-radius: 5px;
        // transform: scale(0.5, 0.5);
        // transition: all 1s ease 0s;
      }
      li:active {
        border: 1px solid #ffffff;
        border-radius: 5px;
      }
      li:last-child:hover {
        border: none;
        padding: 0px 0px;
      }
      li:last-child:active {
        border: none;
        padding: 0px 0px;
      }
      li a {
        display: block;
        height: 100%;
        width: 100%;
        text-decoration: none;
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 93%;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
    // .button {
    //   font-family: 'Archivo';
    //   font-style: normal;
    //   font-weight: 700;
    //   font-size: 16px;
    //   line-height: 92.99%;
    //   text-transform: uppercase;
    //   background: #e6321f;
    //   border-radius: 10px;
    //   padding: 13px 20px;
    //   text-shadow: 1px 1px 0px rgba(0, 0, 0, 0);
    //   cursor: pointer;
    // }
    // .button:hover {
    //   // transform: scale(0.5, 0.5);
    //   text-shadow: 2px 2px 0px rgba(0, 0, 0, 1);
    // }
    &__cart {
      position: relative;
      cursor: pointer;
      &__q {
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 93%;
        text-transform: uppercase;
        background: rgba(255, 0, 0, 1);
        border-radius: 50%;
        height: 15px;
        width: 15px;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(50%);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &__cart:hover > div,
    .pulsate {
      animation-name: cart__q;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
    }
  }
  .Burger {
    display: none;
  }
  &__mobile {
    display: none;
  }
}
@media screen and (max-width: 1440px) {
  .Header {
    &__menu {
      &__list {
        li a {
          font-size: 14px !important;
        }
      }
    }
  }
}
@media screen and (max-width: 1133px) {
  .Header {
    &__menu {
      display: none;
    }
    &__mobile {
      display: flex;
      gap: 40px;
      margin-left: 40px;
      align-items: center;
      &__burger__icon {
        z-index: 100;
        display: block;
        position: relative;
        width: 52px;
        height: 12px;
        cursor: pointer;
      }
    }
  }
  .Header__mobile__burger__icon span,
  .Header__mobile__burger__icon::before,
  .Header__mobile__burger__icon::after {
    left: 0;
    position: absolute;
    height: 10%;
    width: 100%;
    transition: all 0.3s ease 0s;
    background-color: #fff;
  }
  .Header__mobile__burger__icon::before,
  .Header__mobile__burger__icon::after {
    content: '';
  }
  .Header__mobile__burger__icon::before {
    top: 0;
  }
  .Header__mobile__burger__icon::after {
    bottom: 0;
  }
  .Header__mobile__burger__icon span {
    top: 50%;
    transform: scale(1) translate(0px, -50%);
  }
  .Header__mobile__burger__icon_active span {
    transform: scale(0) translate(0px, -50%);
  }
  .Header__mobile__burger__icon_active::before {
    top: 50%;
    transform: rotate(-45deg) translate(0px, -50%);
  }
  .Header__mobile__burger__icon_active::after {
    bottom: 50%;
    transform: rotate(45deg) translate(0px, 50%);
  }
}
@media screen and (max-width: 768px) {
  .Header {
    padding: 15px;
    height: 70px;
    &__logo {
      img {
        height: auto;
        width: 100%;
        max-width: 300px;
      }
    }
    &__mobile {
      gap: 15px;
      margin-left: 15px;
      &__burger__icon {
        justify-self: end;
        width: 40px;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .Header {
    &__logo {
      img {
        height: auto;
        max-width: 270px;
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .Header {
    padding: 13px;
    &__logo {
      img {
        height: auto;
        width: 210px;
      }
    }
    &__mobile {
      gap: 10px;
      margin-left: 10px;
    }
  }
}
