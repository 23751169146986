.MerchHero {
  width: 100%;
  padding: 120px 50px 0 50px;
  display: grid;
  grid-template: minmax(auto, 900px) / 50% 1fr;
  &__info {
    width: 60%;
    margin: auto;

    h1 {
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 100%;
      text-transform: uppercase;
      margin-bottom: 25px;
    }
    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__status {
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        text-transform: uppercase;
        color: #000000;
        background: rgba(255, 214, 0, 0.42);
        border: 1px solid rgba(255, 255, 255, 0.4);
        box-shadow: inset 0px 0px 10px 10px rgba(255, 199, 0, 0.25);
        border-radius: 7px;
        padding: 8px 10px;
      }
      &__num {
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        text-transform: uppercase;
        color: #ffffff;
        span {
          background: transparent;
          border: 1px solid #ffffff;
          border-radius: 5px;
          margin-right: 7px;
          padding: 8px 10px;
        }
      }
      &__quantity {
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        text-transform: uppercase;
        color: #ffffff;
        span {
          background: transparent;
          border: 1px solid #ffffff;
          border-radius: 24px;
          margin-right: 7px;
          padding: 8px 20px;
        }
      }
    }
    p {
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      text-transform: uppercase;
      color: #ffffff;
      opacity: 0.5;
      margin: 40px 0;
    }
    &__buy {
      width: 100%;
      display: flex;
      align-items: center;
      button {
        padding: 0;
        border: none;
        font: inherit;
        color: inherit;
        background-color: transparent;
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 93%;
        text-transform: uppercase;
        color: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 10px;
        height: 60px;
        width: 220px;
        margin-right: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        /* pulse */
        position: relative; /* Добавляем позиционирование */
      }
      button::before {
        /* Добавляем псевдоэлемент */
        content: '';
        position: absolute; /* Позиционируем псевдоэлемент относительно кнопки */
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.3);
        animation: pulse 1s ease-out;
        z-index: -1; /* Отправляем псевдоэлемент под кнопку */
        opacity: 0; /* Скрываем псевдоэлемент */
      }

      @keyframes pulse {
        0% {
          transform: scale(1);
          opacity: 0.5;
        }
        100% {
          transform: scale(1.5);
          opacity: 0;
        }
      }

      button:active {
        /* Добавляем стили для состояния активности (кнопка нажата) */
        transform: scale(0.95); /* Уменьшаем кнопку */
      }

      button:active::before {
        /* Добавляем стили для псевдоэлемента в состоянии активности */
        opacity: 1; /* Показываем псевдоэлемент */
        animation: 2s ease-out;
      }

      h2 {
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 93%;
        text-transform: uppercase;
        color: #ffffff;
      }
    }
  }
  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 900px;
    background-position: 0 0 !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative;
    .Arrow__left {
      left: 0;
      background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }
    .gradientTop {
      position: absolute;
      top: 0;
      left: 0;
      height: 80px;
      width: 100%;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }
    .gradientBottom {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 80px;
      width: 100%;
      background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }
    .img {
      width: 70%;
      height: auto;
    }
  }
}
@media screen and (max-width: 1440px) {
  .MerchHero {
    grid-template: minmax(auto, 700px) / 50% 1fr;
    padding: 120px 50px 0 50px;
    &__info {
      &__item {
        &__status {
          font-size: 14px;
        }
        &__num {
          font-size: 14px;
        }
        &__quantity {
          font-size: 14px;
        }
      }
      p {
        font-size: 14px;
        margin: 30px 0;
      }
    }
    &__img {
      height: 700px;
    }
  }
}
@media screen and (max-width: 1133px) {
  .MerchHero {
    display: block;
    &__img {
      width: 100%;
      margin-bottom: 50px;
    }
    &__info {
      width: 60%;
      &__item {
        width: 100%;
      }
      &__buy {
        flex-direction: row-reverse;
        justify-content: space-between;
        button {
          margin-right: 0;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .MerchHero {
    padding: 70px 15px 0px 15px;
    &__info {
      width: 80%;
      h1 {
        font-size: 24px;
        margin-bottom: 10px;
      }
      &__item {
        &__status {
          font-size: 12px;
          line-height: 93%;
          padding: 6px 8px;
        }
        &__num {
          font-size: 12px;
          line-height: 93%;
          span {
            padding: 4px 8px;
          }
        }
        &__quantity {
          font-size: 12px;
          line-height: 93%;
          span {
            padding: 4px 15px;
          }
        }
      }
      &__buy {
        h2 {
          font-size: 30px;
        }
        button {
          height: 40px;
          width: 200px;
        }
      }
    }
    &__img {
      height: 700px;
      .Arrow__left {
        left: 0px;
      }
      .Arrow__right {
        right: 0px;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .MerchHero {
    &__info {
      width: 100%;
    }
    &__img {
      height: 400px;
    }
  }
}
@media screen and (max-width: 320px) {
  .MerchHero {
    padding: 70px 13px 0 13px;

    &__img {
      height: 320px;
    }
  }
}
