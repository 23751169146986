.Roadmap {
  padding: 60px 50px 100px 50px;
  width: 100%;
  h3 {
    font-family: 'Archivo Narrow';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 93%;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 20px;
  }
  &__Options {
    width: 100%;
    .Collapsible {
      background: rgba(255, 255, 255, 0.06);
      border: 1px solid transparent;
      border-radius: 10px;
      margin-bottom: 10px;
      padding: 25px;
      position: relative;
      height: min-content;
      overflow: hidden !important;
      .Collapsible__trigger {
        display: flex;
        h2 {
          width: 50%;
          font-family: 'Archivo Narrow';
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 100%;
          text-transform: uppercase;
          color: #ffffff;
          display: flex;
          align-items: center;
          cursor: pointer;
          padding-right: 30px;
          span {
            background: transparent;
            border: 1px solid #ffffff;
            border-radius: 5px;
            margin-right: 17px;
            padding: 8px 11px;
            font-family: 'Archivo Narrow';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            text-transform: uppercase;
            color: #ffffff;
          }
        }
      }
      .Collapsible__trigger.is-closed {
        h2 {
          opacity: 0.2;
        }
      }

      ul {
        width: 50%;
        transform: translateX(6%);
        margin: 30px 0;
        padding-right: 30px;
        li {
          font-family: 'Archivo';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 200%;
          text-transform: uppercase;
          margin-bottom: 5px;
          color: #99daff;
        }
        .done {
          font-family: 'Archivo';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 200%;
          text-transform: uppercase;
          text-decoration-line: line-through;
          text-transform: uppercase;
          color: #99ffaf;
        }
        :last-child {
          margin-bottom: 0px;
        }
      }
      video {
        position: absolute;
        top: 0;
        right: 0;
        width: 48%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px !important;
        overflow: hidden;
      }
      .gradient {
        position: absolute;
        top: 0;
        right: 25%;
        width: 25%;
        height: 100%;
        background: linear-gradient(
          270deg,
          #0f0f0f 20.31%,
          rgba(15, 15, 15, 0) 100%
        );
        transform: matrix(-1, 0, 0, 1, 0, 0);
        z-index: 10;
      }
    }
    .Collapsible:hover,
    .Collapsible.is-open {
      border: 1px solid #99daff;
    }
  }
}
@media screen and (max-width: 768px) {
  .Roadmap {
    padding: 0px 15px 50px 15px;
    max-width: 768px;

    &__Options {
      .Collapsible {
        padding: 20px;
        .Collapsible__trigger {
          h2 {
            width: 100%;
            font-size: 16px;
            margin-top: 55%;
          }
        }

        ul {
          width: 100%;
          transform: translateX(5%);
          margin: 20px 0;
          li {
            font-size: 14px;
          }
          .done {
            font-size: 14px;
          }
        }
        video {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 50%;
          object-fit: cover;
        }
        .gradient {
          position: absolute;
          top: 27%;
          right: 0;
          width: 100%;
          height: 25%;
          background: linear-gradient(
            0deg,
            #0f0f0f 20.31%,
            rgba(15, 15, 15, 0) 100%
          );
          transform: matrix(-1, 0, 0, 1, 0, 0);
          z-index: 10;
        }
        .Collapsible__trigger.is-closed {
          h2 {
            margin-top: 0px !important;
          }
        }
        .Collapsible__trigger.is-closed + .Collapsible__contentOuter {
          .Collapsible__contentInner video {
            position: absolute;
            top: 0;
            right: 0;
            width: 30%;
            height: 100%;
            object-fit: cover;
          }
        }
        .Collapsible__trigger.is-closed + .Collapsible__contentOuter {
          .Collapsible__contentInner .gradient {
            position: absolute;
            top: 0;
            right: 15%;
            width: 20%;
            height: 100%;
            background: linear-gradient(
              270deg,
              #0f0f0f 20.31%,
              rgba(15, 15, 15, 0) 100%
            );
            transform: matrix(-1, 0, 0, 1, 0, 0);
            z-index: 10;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .Roadmap {
    &__Options {
      .Collapsible {
        .Collapsible__trigger {
          h2 {
            margin-top: 85%;
            span {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .Roadmap {
    padding: 0px 13px 50px 13px;
    max-width: 320px;
    &__Options {
      .Collapsible {
        .Collapsible__trigger {
          h2 {
            margin-top: 120%;
          }
        }
      }
    }
  }
}
