%bg {
  background: rgba(36, 0, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: inset 0px 0px 20px rgba(0, 194, 255, 0.25),
    inset 0px 4px 10px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 5px;
  // animation: glowing 1s infinite;
}

// @keyframes glowing {
//   0% {
//     box-shadow: inset 0px 0px 20px rgba(0, 194, 255, 0.25),
//       inset 0px 4px 10px rgba(0, 0, 0, 0.5);
//   }
//   50% {
//     box-shadow: inset 0px 0px 30px rgba(0, 194, 255, 0.25),
//       inset 0px 4px 10px rgba(0, 0, 0, 0.5);
//   }
//   100% {
//     box-shadow: inset 0px 0px 20px rgba(0, 194, 255, 0.25),
//       inset 0px 4px 10px rgba(0, 0, 0, 0.5);
//   }
// }

// font-size: calc(24px + 16 * ((100vw - 320px) / (1280 - 320)));

.Hero {
  * {
    font-family: 'Archivo Narrow';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 93%;
    text-transform: uppercase;
    color: #99daff;
  }
  width: 100%;
  // background: radial-gradient(
  //   30% 40% at 40% 40%,
  //   rgba(0, 26, 255, 0.4) 0%,
  //   rgba(0, 0, 0, 0) 100%
  // );
  padding: 40px 50px;
  display: grid;
  grid-template: minmax(auto, min-content) / min-content 1fr 40%;
  overflow: hidden;
}

.Hero__tigers {
  grid-column: 1 / 2;
  height: 100%;
  width: 100%;
  .swiper-slide {
    width: 90px;
  }
  &__gallery {
    display: grid;
    align-content: space-around;
    height: calc(100% - 26px);
    grid-template: repeat(4, 100px) / repeat(2, 100px);
    gap: 20px;
    padding: 40px 20px;
    margin-top: 10px;
    @extend %bg;
    img {
      width: 100px;
      height: 100px;
      object-fit: contain;
      cursor: pointer;
      border-radius: 5px;
    }
    img.active {
      border: 2px solid #99daff; /* синий цвет */
      position: relative;
      animation: pulsate 2s ease-out;
      animation-iteration-count: infinite;
    }
    @keyframes pulsate {
      0% {
        box-shadow: 0 0 0 0 rgba(0, 191, 255, 0.7);
      }

      50% {
        box-shadow: 0 0 0 10px rgba(0, 191, 255, 0);
      }

      100% {
        box-shadow: 0 0 0 0 rgba(0, 191, 255, 0);
      }
    }
  }
  .mobile {
    display: none;
  }
}

.Hero__tigrilla {
  height: 100%;
  width: 100%;
  grid-column: 2 / 3;
  .gradient {
    height: 100%;
    width: 100%;
    // background: radial-gradient(
    //   50% 50% at 50% 50%,
    //   rgba(0, 0, 0, 0) 0%,
    //   #000000 100%
    // );
  }
  &__video {
    position: relative;
    width: 100%;
    height: 100%;
    &__loader {
      position: absolute;
      left: calc(50% - 64px);
      top: calc(50% - 64px);
      height: 128px;
      width: 128px;
      background-size: 110% 110% !important;
      background-position: center !important;
    }
    video {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.Hero__episode {
  width: 100%;
  height: 100%;
  grid-column: 3 / 4;
  display: grid;
  grid-template: min-content min-content min-content / 1fr 50%;
  gap: 10px;
  &__title {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    display: grid;
    grid-template: 1fr / auto auto;
    a {
      justify-self: end;
    }
  }
  &__video {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    @extend %bg;
    padding: 20px;
    height: fit-content;
  }
  .title {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    align-self: center;
    width: 100%;
    height: fit-content;
  }
  .more {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    width: fit-content;
    height: fit-content;
    justify-self: end !important;
    align-self: start;
    background: rgba(36, 0, 255, 0.1);
    border: 1px solid #99daff;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 700;
    line-height: 93%;
  }
  &__info {
    width: 100%;
    height: 100%;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    display: grid;
    grid-template: min-content auto min-content / 1fr 50%;
    @extend %bg;
    padding: 20px;
    align-items: start;
    &__description {
      grid-row: 2 / 3;
      grid-column: 1 / 3;
      margin: auto 0;
      padding: 20px 0;
      height: min-content;
    }

    // &__location {
    //   grid-column: 1 / 2;
    //   grid-row: 3 / 4;
    //   display: grid;
    //   column-gap: 15px;
    //   margin-bottom: 20px;
    //   align-self: center;
    //   img {
    //     height: 40px;
    //     width: 28px;
    //   }
    //   &__text {
    //     grid-column: 2 / 3;
    //     align-self: center;
    //     p {
    //       font-family: 'Archivo Narrow';
    //       font-style: normal;
    //       font-weight: 400;
    //       color: white;
    //       padding-top: 6px;
    //       font-weight: 400;
    //       line-height: 100%;
    //     }
    //   }
    // }

    &__socials {
      grid-row: 3 / 4;
      grid-column: 1 / 3;
      display: flex;
      align-self: end;
      justify-content: start;
      a {
        margin-right: 10px;
        img {
          width: auto;
          height: 20px;
          fill: #99daff;
        }
      }
      :last-child {
        margin-right: 0px;
      }
    }
  }
  &__stuff {
    width: 100%;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    display: grid;
    grid-template: min-content 1fr min-content / 1fr 50%;
    @extend %bg;
    padding: 20px;
    img {
      grid-row: 2 / 3;
      grid-column: 1 / 3;
      width: 80%;
      height: auto;
      align-self: center;
      justify-self: center;
      margin: 20px 0;
    }
    &__name {
      grid-row: 3 / 4;
      grid-column: 1 / 3;
      font-weight: 400;
      justify-self: center;
    }
  }
}

@media screen and (max-width: 1440px) {
  .Hero {
    * {
      font-size: 14px;
    }
  }

  .Hero__tigers {
    &__gallery {
      height: calc(100% - 24px);
      grid-template: repeat(4, 90px) / repeat(2, 90px);
      gap: 10px;
      img {
        width: 90px;
        height: 90px;
      }
    }
  }
}

@media screen and (max-width: 1133px) {
  .Hero {
    grid-template: auto min-content / auto 1fr;
    background: none;
  }

  .Hero__tigers {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    &__gallery {
      gap: 20px;
    }
  }

  .Hero__tigrilla {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    // background: radial-gradient(
    //   40% 40% at 40% 40%,
    //   rgba(0, 26, 255, 0.4) 0%,
    //   rgba(0, 0, 0, 0) 100%
    // );
  }

  .Hero__episode {
    grid-column: 1 / 3 !important;
    grid-row: 2 / 3;
    margin-top: 40px;
  }
}
@media screen and (max-width: 768px) {
  .Hero {
    padding: 50px 15px 100px 15px;
    grid-template: auto auto auto / 100%;
  }
  .Hero__tigers {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    width: 100%;
    height: fit-content;
    margin: 20px auto 0px auto;
    &__gallery {
      width: 100%;
      height: fit-content;
      overflow: hidden;
      padding: 20px;
      background: rgba(255, 255, 255, 0.06);
      border-radius: 5px;
      border: none;
      box-shadow: none;
      backdrop-filter: none;
    }
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
      position: relative;
      .Arrow__left {
        left: 0px;
      }
      .Arrow__right {
        right: 0px;
      }
    }
  }
  .Hero__tigrilla {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    width: 100%;
    height: 500px;
    background: none;
  }
  .Hero__episode {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
    &__video {
      width: 100%;
      padding: 15px;
      background: rgba(255, 255, 255, 0.06);
      border-radius: 5px;
      border: none;
      box-shadow: none;
      backdrop-filter: none;
    }
    &__info {
      padding: 15px;
      background: rgba(255, 255, 255, 0.06);
      border-radius: 5px;
      border: none;
      box-shadow: none;
      backdrop-filter: none;
      &__description {
        a {
          font-size: 12px;
        }
      }
      &__socials {
        img {
          width: auto;
          height: 14px;
        }
      }
    }
    &__stuff {
      padding: 15px;
      background: rgba(255, 255, 255, 0.06);
      border-radius: 5px;
      border: none;
      box-shadow: none;
      backdrop-filter: none;
      img {
        margin: 15px 0;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .Hero__tigers {
    &__gallery {
      padding: 15px;
    }
  }
  .Hero__tigrilla {
    height: 400px;
  }
  .Hero__episode {
    &__info {
      padding: 15px;
      &__socials {
        justify-content: space-between;
        a {
          margin-right: 0px;
          img {
            width: auto;
            height: 14px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .Hero {
    padding: 50px 13px;
  }
  .Hero__episode {
    &__video {
      padding: 13px;
    }
    &__info {
      padding: 13px;
    }
  }
}
