.Arrow {
  height: 100%;
  width: 80px;
  z-index: 9;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 40px;
    width: 20px;
  }
}
.Arrow__left {
  left: 0;
  background: none;
}
.Arrow__right {
  right: 0;
  background: linear-gradient(-90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
}
.Arrow__disabled {
  display: none;
}
@media screen and (max-width: 768px) {
  .Arrow {
    width: 60px;
    img {
      height: 30px;
      width: 15px;
    }
  }
  .Arrow__left {
    left: 15px;
  }
  .Arrow__right {
    right: 15px;
  }
}
@media screen and (max-width: 320px) {
  .Arrow {
    width: 40px;
    img {
      height: 30px;
      width: 15px;
    }
  }
  .Arrow__left {
    left: 13px;
  }
  .Arrow__right {
    right: 13px;
  }
}
