.Footer {
  max-width: 1920px;
  width: 100%;
  padding: 0 50px;
  height: 270px;
  &__container {
    width: 100%;
    height: 100%;
    padding: 0px 50px 40px 50px;
    display: grid;
    grid-template: 170px 100px / 1fr 50%;
    background: #0e0e0e;
    position: relative;
    border-radius: 10px 10px 0 0;
    &__line {
      width: 100%;
      height: 1px;
      position: absolute;
      top: 180px;
      left: 0;
      z-index: 10;
      background-color: rgba(255, 255, 255, 0.1);
    }
    // &__menu {
    //   grid-column: 1 / 2;
    //   grid-row: 1 / 2;
    //   justify-self: start;
    //   align-self: center;
    //   display: grid;
    //   grid-template: 1fr / auto auto;
    //   font-family: 'Archivo';
    //   font-style: normal;
    //   font-weight: 400;
    //   font-size: 14px;
    //   line-height: 93%;
    //   text-transform: uppercase;
    //   color: #ffffff;
    //   opacity: 0.5;
    //   ul {
    //     width: 100%;
    //     li {
    //       width: 100%;
    //       margin-bottom: 10px;
    //     }
    //   }
    //   :first-child {
    //     margin-right: 80px;
    //   }
    // }
    &__subscribe {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      align-self: center;
      justify-self: end;
      display: grid;
      width: 440px;
      grid-template: auto 1fr / 1fr min-content;
      .email {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        width: 440px;
        height: 50px;
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 92.99%;
        text-transform: uppercase;
        background-color: black;
        border: 1px solid #ffffff;
        border-radius: 10px;
        padding-left: 20px;
      }
      .submit {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        justify-self: end;
        width: 135px;
        height: 50px;
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 92.99%;
        text-transform: uppercase;
        background-color: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 10px;
        color: black !important;
        text-align: center;
        padding-left: 0px;
      }
      p {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 119.99%;
        color: #ffffff;
        opacity: 0.5;
        margin-bottom: 10px;
        text-transform: uppercase;
      }
    }
    &__copyrighting {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 93%;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.5);
      display: flex;
      align-items: end;
      padding-bottom: 40px;
    }
    &__partners {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      display: grid;
      grid-template: 1fr / repeat(4 1fr);
      grid-auto-flow: column;
      column-gap: 80px;
      align-items: center;
      justify-self: start;
      a img {
        max-width: 130px;
        max-height: 60px;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
    &__socials {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: end;
      justify-content: end;
      padding-bottom: 40px;
      a {
        margin-left: 15px;
        img {
          width: auto;
          height: 20px;
          fill: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}
@media screen and (max-width: 1133px) {
  .Footer {
    max-width: 1133px;
    height: fit-content;
    &__container {
      padding: 0px 50px 35px 50px;
      display: grid;
      grid-template: min-content auto min-content / 30% 1fr;
      &__line {
        display: none;
      }
      // &__menu {
      //   grid-column: 1 / 3;
      //   grid-row: 3 / 4;
      //   justify-content: center;
      //   align-items: center;
      //   display: grid;
      //   // grid-template: 1fr / 50% 1fr;
      //   font-size: 10px;
      //   width: 100%;
      //   :first-child {
      //     margin-right: 0px;
      //   }
      // }
      &__subscribe {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        align-self: start;
        justify-self: center;
        width: 60%;
        margin: 40px auto;
        color: #ffffff;
        opacity: 0.5;
        .email {
          width: 100%;
          height: 50px;
          font-size: 12px;
          line-height: 93%;
        }
        .submit {
          width: 100px;
          font-size: 12px;
        }
        p {
          font-size: 12px;
        }
      }
      &__partners {
        grid-column: 1 / 3;
        width: 100%;
        grid-row: 2 / 3;
        grid-auto-flow: column;
        column-gap: 30px;
        margin-bottom: 40px;
        justify-self: center;
      }
      &__copyrighting {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        justify-self: start;
        align-self: center;
        padding-bottom: 0px;
      }
      &__socials {
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        align-self: center;
        justify-self: end;
        padding-bottom: 0px;
        height: fit-content;
        a {
          margin-left: 15px;
          width: auto;
          height: 18px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .Footer {
    max-width: 768px;
    padding: 0 15px;
    &__container {
      padding: 0px 15px 40px 15px;
      &__subscribe {
        width: 80%;
        .email {
          height: 40px;
          width: 100%;
        }
        .submit {
          height: 40px;
          width: 90px;
        }
      }
      &__partners {
        column-gap: 20px;
        align-items: center;
        margin-bottom: 30px;
        a {
          width: 100%;
          height: 100%;
          img {
            max-width: 130px;
            max-height: 60px;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
      }
      &__socials {
        padding-bottom: 0px;
        a {
          margin-left: 10px;
          img {
            width: auto;
            height: 20px;
            fill: rgba(255, 255, 255, 0.5);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .Footer {
    max-width: 480px;
    &__container {
      padding: 0px 15px 30px 15px;
      &__subscribe {
        width: 100%;
        margin: 30px 0;
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .Footer {
    max-width: 320px;
    padding: 0px 13px;
  }
}
