.SeriesHero {
  width: 100%;
  padding: 25px 50px;
  display: grid;
  grid-template: auto / 1fr 60%;
  &__info {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    padding-right: 90px;
    margin: auto 0;
    h1 {
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 900;
      font-size: 40px;
      line-height: 100%;
      text-transform: uppercase;
      margin-bottom: 25px;
    }
    h5 {
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      span {
        background: transparent;
        border: 1px solid #ffffff;
        border-radius: 5px;
        margin-right: 7px;
        padding: 8px 10px;
      }
    }
    p {
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 107%;
      text-transform: uppercase;
      color: #ffffff;
      opacity: 0.5;
      margin: 40px 0;
    }
    &__stuff {
      background: rgba(104, 104, 104, 0.2);
      backdrop-filter: blur(20px);
      border-radius: 10px;
      padding: 20px;
      display: grid;
      grid-template: 1fr / auto 1fr min-content;
      align-items: center;
      width: 100%;
      img {
        grid-column: 1 / 2;
        width: auto;
        height: 70px;
      }
      .stuff__info {
        grid-column: 2 / 3;
        padding: 0 30px;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center !important;
        h6 {
          font-family: 'Archivo Narrow';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 93%;
          text-transform: uppercase;
          margin-bottom: 5px;
          color: #ffffff;
          height: fit-content;
        }
        span {
          font-family: 'Archivo Narrow';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 100%;
          text-transform: uppercase;
          color: #ffffff !important;
          height: fit-content;
          margin: 0 0;
        }
      }
      button {
        grid-column: 3 / 4;
        background-color: transparent;
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 93%;
        text-transform: uppercase;
        color: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 10px;
        padding: 10px 20px;
        position: relative; /* Добавляем позиционирование */
      }
      button::before {
        /* Добавляем псевдоэлемент */
        content: '';
        position: absolute; /* Позиционируем псевдоэлемент относительно кнопки */
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.3);
        animation: pulse 1s ease-out;
        z-index: -1; /* Отправляем псевдоэлемент под кнопку */
        opacity: 0; /* Скрываем псевдоэлемент */
      }

      @keyframes pulse {
        0% {
          transform: scale(1);
          opacity: 0.5;
        }
        100% {
          transform: scale(1.5);
          opacity: 0;
        }
      }

      button:active {
        /* Добавляем стили для состояния активности (кнопка нажата) */
        transform: scale(0.95); /* Уменьшаем кнопку */
      }

      button:active::before {
        /* Добавляем стили для псевдоэлемента в состоянии активности */
        opacity: 1; /* Показываем псевдоэлемент */
        animation: 2s ease-out;
      }
    }
  }
  &__video {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 1440px) {
  .SeriesHero {
    &__info {
      margin: 0;
      h5 {
        font-size: 14px;
      }
      p {
        font-size: 14px;
      }
      &__stuff {
        img {
          grid-column: 1 / 2;
          width: auto;
          height: 50px;
        }
        .stuff__info {
          h6 {
            font-size: 14px;
          }
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1133px) {
  .SeriesHero {
    grid-template: auto 1fr / 100%;
    &__info {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      padding-right: 0px;
      width: 60%;
      margin: 0 auto;
    }
    &__video {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      width: 100%;
      height: auto;
      margin-bottom: 50px;
    }
  }
}
@media screen and (max-width: 768px) {
  .SeriesHero {
    padding: 0 15px;
    &__info {
      width: 80%;
      h5 {
        font-size: 12px;
        span {
          padding: 4px 8px;
        }
      }
      h1 {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 10px;
      }
      p {
        margin: 30px 0;
      }
    }
    &__video {
      margin-bottom: 20px;
    }
  }
}
@media screen and (max-width: 480px) {
  .SeriesHero {
    &__info {
      width: 100%;

      p {
        margin: 20px 0;
      }
    }
  }
}
