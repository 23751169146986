.MerchCard {
  height: 450px;
  width: 24%;
  background: rgba(255, 255, 255, 0.06);
  border: 1px solid transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70% auto;
  border-radius: 10px;
  position: relative;

  &:hover {
    border: 1px solid #99daff;
  }
  &:hover &__num,
  &:hover &__status,
  &:hover &__info {
    opacity: 1;
    transition: all 0.3s;
  }
  &__num {
    position: absolute;
    top: 30px;
    left: 20px;
    width: fit-content;
    height: fit-content;
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    text-transform: uppercase;
    color: #ffffff;
    opacity: 0;
    span {
      background: transparent;
      border: 1px solid #ffffff;
      border-radius: 5px;
      margin-right: 7px;
      padding: 6px 10px;
    }
  }
  &__status {
    position: absolute;
    top: 20px;
    right: 20px;
    width: fit-content;
    height: fit-content;
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    text-transform: uppercase;
    color: #000000;
    background: rgba(255, 214, 0, 0.42);
    border: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: inset 0px 0px 10px 10px rgba(255, 199, 0, 0.25);
    border-radius: 7px;
    padding: 8px 10px;
    opacity: 0;
  }
  &__info {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: fit-content;
    height: fit-content;
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-transform: uppercase;
    color: #ffffff;
    opacity: 0;
  }
}
@media screen and (max-width: 1440px) {
  .MerchCard {
    height: 350px;
    &__nam {
      font-size: 14px;
    }
    &__status {
      font-size: 14px;
    }
    &__status {
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 1200px) {
  .MerchCard {
    width: 32%;
    height: 350px;
  }
}
@media screen and (max-width: 800px) {
  .MerchCard {
    width: 48%;
    height: 370px;
  }
}
@media screen and (max-width: 600px) {
  .MerchCard {
    width: 80%;
    height: 450px;
    margin: 0 auto;
    &__num {
      font-size: 12px;
      span {
        padding: 4px 8px;
      }
    }
    &__status {
      font-size: 12px;
      padding: 7px 10px;
    }
    &__info {
      font-size: 12px;
    }
  }
}
@media screen and (max-width: 430px) {
  .MerchCard {
    width: 100%;
    height: 430px;
  }
}
@media screen and (max-width: 375px) {
  .MerchCard {
    height: 375px;
  }
}
@media screen and (max-width: 320px) {
  .MerchCard {
    height: 320px;
  }
}

.MerchCard_lock {
  height: 450px;
  width: 24%;
  background: rgba(255, 255, 255, 0.06);
  border: 1px solid;
  border-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70px 120px;
  border-radius: 10px;
}
@media screen and (max-width: 1440px) {
  .MerchCard_lock {
    height: 350px;
  }
}
@media screen and (max-width: 1200px) {
  .MerchCard_lock {
    width: 32%;
    height: 350px;
  }
}
@media screen and (max-width: 800px) {
  .MerchCard_lock {
    width: 48%;
    height: 370px;
  }
}
@media screen and (max-width: 600px) {
  .MerchCard_lock {
    width: 80%;
    height: 450px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 430px) {
  .MerchCard_lock {
    height: 430px;
    width: 100%;
  }
}
@media screen and (max-width: 375px) {
  .MerchCard_lock {
    height: 375px;
  }
}
@media screen and (max-width: 320px) {
  .MerchCard_lock {
    height: 320px;
  }
}

.MerchCard_timer {
  font-family: 'Archivo Narrow';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 93%;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.5;
  background: none;
  position: absolute;
  top: 45%;
  left: 0;
  width: 100%;
  display: flex;
  align-self: center;
  justify-self: center;
}
