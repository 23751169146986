.Burger {
  transform: translateY(-100%);
  transition: all 0.5s ease-in-out;
  grid-column: 1 / 4;
  width: 100%;
  min-height: 100vh;
  position: fixed;
  left: 0;
  z-index: 50;
  background-color: black;
  padding: 0 50px 50px 50px;
  display: grid;
  &_active {
    transform: translateY(0%);
  }
  &__menu {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
    &__list {
      display: grid;
      grid-auto-flow: row;
      row-gap: 30px;
      align-self: center;
      li {
        font-family: 'Archivo Narrow';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 100%;
        text-transform: uppercase;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
      }
    }
    &__cart {
      position: relative;
      width: fit-content;
      justify-self: center;
      cursor: pointer;
      &__q {
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 400;
        font-size: 8px;
        line-height: 92.99%;
        text-transform: uppercase;
        background: rgba(255, 0, 0, 1);
        border-radius: 50%;
        height: 13px;
        width: 13px;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(50%);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &__cart:hover > div,
    .pulsate {
      animation-name: cart__q;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
    }
    // button {
    //   font-family: 'Archivo';
    //   font-style: normal;
    //   font-weight: 700;
    //   font-size: 24px;
    //   line-height: 92.99%;
    //   text-align: center;
    //   text-transform: uppercase;
    //   color: #ffffff;
    //   background: #e6321f;
    //   border-radius: 10px;
    //   align-self: end;
    //   width: 400px;
    //   height: 60px;
    //   align-self: end;
    // }
  }
}
@keyframes cart__q {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(255, 0, 0, 1);
  }

  50% {
    box-shadow: 0px 0px 0px 10px rgba(255, 0, 0, 0);
  }
}
@media screen and (max-width: 768px) {
  .Burger {
    padding: 15px;
    &__menu {
      &__list {
        li {
          font-size: 24px;
        }
      }
      .button {
        width: 100%;
        margin-bottom: 50%;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .Burger {
    padding: 13px;
  }
}
