.Delivery {
  display: grid;
  padding: 220px 0 100px 0;
  width: 60%;
  margin: 0 auto;
  height: min-content;
  grid-template: 1fr / 1fr 40%;
  column-gap: 50px;
  &__info {
    grid-column: 1 / 2;
    width: 100%;
    .checkboxes {
      width: 100%;
      padding-left: 20px;
      .checkbox {
        position: absolute;
        z-index: -1;
        opacity: 0;
      }
      .checkbox + label {
        display: inline-flex;
        align-items: center;
        user-select: none;
        margin-bottom: 15px;
      }
      .checkbox + label::before {
        content: '';
        display: inline-block;
        width: 26px;
        height: 26px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 5px;
        margin-right: 10px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
      }
      // .checkbox:required + label::after {
      //   content: '*';
      //   color: red;
      // }
      .checkbox:checked + label::before {
        background-image: none;
      }
      label {
        font-family: 'Archivo Narrow';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
      }
    }
    label {
      width: 100%;
      font-family: 'Archivo Narrow';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 100%;
      color: #ffffff;
      text-transform: uppercase;
      input {
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 12px;
        margin-bottom: 15px;
        background-color: black;
        width: 100%;
        height: 50px;
        padding-left: 15px;
      }
      input:first-child {
        margin-top: 20px;
      }
      input:last-child {
        margin-bottom: 25px;
      }
      input::placeholder {
        font-family: 'Archivo Narrow';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: rgba(255, 255, 255, 0.5);
        text-transform: uppercase;
      }
      input:focus {
        color: #99daff;
        border: 1px solid #99daff;
        font-size: 16px;
        text-transform: uppercase;
      }
      label + input:required::after {
        content: '*';
        color: red;
      }
    }
  }
  &__order {
    height: fit-content;
    width: 100%;
    grid-column: 2 / 3;
    background: rgba(104, 104, 104, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 20px;
    padding: 25px;
    display: grid;
    grid-auto-flow: row;
    h1 {
      font-family: 'Archivo Narrow';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 100%;
      color: #ffffff;
      text-transform: uppercase;
    }
    &__list {
      margin: 30px 0 10px 0;
      color: #ffffff;
      opacity: 0.5;
    }
    &__subtotal {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      :last-child {
        padding-left: 25px;
      }
    }
    button {
      font-family: 'Archivo Narrow';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      background: #222222;
      border-radius: 12px;
      width: 100%;
      height: 60px;
      text-transform: uppercase;
    }
  }
}
@media screen and (max-width: 1440px) {
  .Delivery {
    padding: 170px 0 100px 0;
    width: 75%;
    &__info {
      label {
        font-size: 20px;
      }
    }
    &__order {
      h1 {
        font-size: 20px;
      }
    }
  }
}
@media screen and (max-width: 1133px) {
  .Delivery {
    width: 55%;
    grid-template: auto auto / 100%;
    column-gap: 0px;
    row-gap: 40px;
    &__info {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      width: 100%;
    }
    &__order {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      width: 100%;
      margin: 0 auto;
      button {
        width: 220px;
        margin: 0 auto;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .Delivery {
    width: 80%;
    padding: 100px 15px 50px 15px;
    &__order {
      &__list {
        margin-bottom: 20px;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .Delivery {
    width: 100%;
    &__info {
      .checkbox:required + label::after {
        transform: translate(-4700%, 30%);
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .Delivery {
    &__info {
      .checkbox:required + label::after {
        transform: translate(-2800%, 30%);
      }
    }
  }
}
