.Mint {
  width: 100%;
  height: 666px;
  padding: 200px 50px 50px 50px;
  display: grid;
  grid-template-columns: 1fr 50%;
  align-items: start;
  &__left {
    grid-column: 1 / 2;
    display: grid;
    grid-auto-flow: row;
    row-gap: 50px;
    span {
      font-family: 'Archivo Narrow';
      font-style: normal;
      font-weight: 400;
      font-size: 64px;
      line-height: 100%;
      text-transform: uppercase;
      color: #ffffff;
    }
    p {
      font-family: 'Archivo Narrow';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 92.99%;
      text-transform: uppercase;
      color: #ff3d00;
      margin-top: 10px;
    }
    &__quantity {
      display: grid;
      grid-auto-flow: row;
      row-gap: 10px;
      grid-template-rows: repeat(2, auto);
      label {
        grid-row: 2 / 3;
      }
      .progress_wrapper {
        background-color: rgba(255, 255, 255, 0.3);
        grid-row: 1 / 2;
        width: 60%;
        height: 5px;
        border-radius: 14px;
        display: grid;
        align-items: center;
        progress {
          background-color: transparent;
          height: 100%;
          width: 100%;
        }
        progress::-webkit-progress-bar {
          background-color: transparent;
        }
        progress::-webkit-progress-value {
          background-color: #ff3d00;
          border-radius: 14px;
        }
        progress::-moz-progress-bar {
          background-color: #ff3d00;
          border-radius: 14px;
        }
      }
    }
  }
  &__right {
    grid-column: 2 / 3;
    display: grid;
    grid-auto-flow: row;
    h1 {
      font-family: 'Archivo Narrow';
      font-style: normal;
      font-weight: 400;
      font-size: 64px;
      line-height: 100%;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 30px;
    }
    form {
      margin-bottom: 30px;
      legend {
        font-family: 'Archivo Narrow';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 92.99%;
        text-transform: uppercase;
        color: #ffffff;
        margin-bottom: 10px;
      }
      input {
        border: 1px solid #99daff;
        border-radius: 12px;
        width: 320px;
        height: 50px;
        background-color: black;
        padding-left: 15px;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      input[type='number'] {
        -moz-appearance: textfield;
      }
      input:required + label::after {
        content: '*';
        color: red;
      }
    }
    p {
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 92.99%;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 10px;
      span {
        color: #ff3d00;
      }
    }
    button {
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 92.99%;
      text-transform: uppercase;
      background: #e6321f;
      border-radius: 10px;
      text-shadow: 1px 1px 0px rgba(0, 0, 0, 0);
      width: 320px;
      height: 50px;
    }
    button:hover {
      text-shadow: 2px 2px 0px rgba(0, 0, 0, 1);
    }
  }
}
@media screen and (max-width: 100px) {
  .Mint {
    width: 100%;
    height: 100%;
    padding: 200px 50px 200px 50px;
    display: grid;
    grid-template: auto auto/ 100%;
    grid-auto-flow: row;
    row-gap: 100px;
    align-items: start;
    &__left {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      display: grid;
      grid-auto-flow: row;
      row-gap: 30px;
      &__quantity {
        progress {
          width: 50%;
        }
      }
    }
    &__right {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      display: grid;
      grid-auto-flow: row;
    }
  }
}
@media screen and (max-width: 750px) {
  .Mint {
    padding: 150px 15px 100px 15px;
    &__left {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      display: grid;
      grid-auto-flow: row;
      row-gap: 30px;
      &__quantity {
        progress {
          width: 70%;
        }
      }
    }
    &__right {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      display: grid;
      grid-auto-flow: row;
    }
  }
}
@media screen and (max-width: 375px) {
  .Mint {
    padding: 100px 13px 50px 13px;
    &__left {
      row-gap: 30px;
    }
    &__right {
      form {
        input {
          width: 90%;
          height: 40px;
        }
      }
      button {
        width: 90%;
        height: 40px;
      }
    }
  }
}
