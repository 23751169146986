.Card {
  display: block;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.06);
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  .blur {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(15px);
    overflow: hidden;
    border-radius: 10px;
  }
  h5 {
    position: absolute;
    top: 20px;
    left: 20px;
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    span {
      border: 1px solid #ffffff;
      border-radius: 5px;
      margin-right: 7px;
      padding: 6px 10px;
    }
  }
}
@media screen and (max-width: 768px) {
  .Card {
    h5 {
      font-size: 12px;
      span {
        padding: 4px 8px;
      }
    }
  }
}

.Card__merch {
  background-size: contain !important;
  .blur {
    display: none;
  }
}

.Card__lock {
  position: relative;
  border-radius: 10px;
  img {
    position: absolute;
    width: 64px;
    height: 64px;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
  }
}

.Card__timer {
  position: relative;
  background: none;
  .timer {
    position: absolute;
    width: 100%;
    height: min-content;
    top: 45%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-family: 'Archivo Narrow';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 93%;
      text-transform: uppercase;
      color: #ffffff;
    }
    :first-child {
      margin-right: 10px;
    }
    :last-child {
      margin-left: 10px;
    }
  }
}
