.CartProduct {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template: 1fr / auto min-content 1fr min-content min-content;
  align-items: center;
  img {
    height: 50px;
    width: 50px;
    object-fit: contain;
    margin-right: 25px;
  }
  h5 {
    font-family: 'Archivo Narrow';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    text-transform: uppercase;
    color: white;
    margin-right: 25px;
  }
  button {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    font-family: 'Archivo Narrow';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
  }
  &__price {
    font-family: 'Archivo Narrow';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    padding: 0 25px;
  }
  &_cart {
    text-align: center;
    padding-bottom: 20px;
  }
  &_delivery {
    text-align: right;
    padding-bottom: 20px;
  }
  &__quantity {
    border: 1px solid #ffffff;
    border-radius: 12px;
    display: flex;
    padding: 5px 10px;
    align-items: center;
    p {
      padding: 0 20px;
      font-family: 'Archivo Narrow';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
    }
  }
  &__close {
    margin-left: 25px;
  }
}
@media screen and (max-width: 768px) {
  .CartProduct {
    img {
      height: 40px;
      width: 40px;
      margin-right: 15px;
    }
    h5 {
      margin-right: 15px;
    }
    &__price {
      padding: 0 15px;
    }
    &_cart {
      padding-bottom: 15px;
    }
    &__quantity {
      p {
        font-size: 14px;
      }
    }
    &__close {
      margin-left: 15px;
    }
  }
}
@media screen and (max-width: 320px) {
  .CartProduct {
    img {
      height: 30px;
      width: 30px;
      margin-right: 10px;
    }
    h5 {
      margin-right: 10px;
    }
    &__price {
      padding-left: 10px;
    }
    &__quantity {
      p {
        padding: 0 10px;
      }
    }
    &__close {
      margin-left: 10px;
    }
  }
}
